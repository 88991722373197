import React, { useState, useRef } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Link } from 'gatsby'
import styles from './private-beta.module.scss'
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { useQueryParam, BooleanParam, StringParam } from 'use-query-params';
import signupIcon from '../images/signup.svg';
import giftIcon from '../images/gift.svg';
import partyIcon from '../images/party.svg';
import copyIcon from '../images/copy.svg';

const InviteLink = ({ referCodeCreator }) => {
  const linkRef = useRef(null);

  const copyToClipboard = (e) => {
    linkRef.current.select();
    document.execCommand('copy');
    e.target.focus();
  };

  return (
    <div className={`relative ${styles.inviteLink}`}>
      <input
        ref={linkRef}
        value={`www.modeofficial.com/private-beta?c=${referCodeCreator}`}
        readOnly
        className={`w-full text-sm text-black px-3 py-2 rounded ${styles.inviteLinkInput}`}
      />

      <img
        src={copyIcon}
        className={`absolute cursor-pointer ${styles.copy}`}
        onClick={copyToClipboard}
        onKeyDown={copyToClipboard}
        aria-hidden='true'
        alt='Copy to clipboard'
      />
    </div>
  );
};

const PrivateBetaPage = () => {
  const [submissionStatus, setSubmissionStatus] = useState('pending');
  const [referCodeCreator, setReferCodeCreator] = useState(null);
  let [contentCreator] = useQueryParam('content-creator', BooleanParam);
  contentCreator = true;

  const [reservedUsername] = useQueryParam('reserved-username', StringParam);
  const modeApiUrl = (process.env.NODE_ENV === 'development') ? 'http://localhost:4000' : 'https://api.modeofficial.com'

  const formHeader = contentCreator ?
    (
      <>
        <h2 className='mt-8'>What do you get as a creator?</h2>

        <div className='flex flex-wrap justify-center mt-4 mb-8 lg:w-3/5 mx-auto'>
          <div className='w-1/3 px-4'>
            <img src={signupIcon} className='my-3 mx-auto' alt='Sign up' />
            <div className='text-sm'>
              $20 for every signup you refer*
            </div>
          </div>
          <div className='w-1/3 px-4'>
            <img src={giftIcon} className='my-3 mx-auto' alt='Gift' />
            <div className='text-sm'>
              Special gifts from us and labels
            </div>
          </div>
          <div className='w-1/3 px-4'>
            <img src={partyIcon} className='my-3 mx-auto' alt='Party and events' />
            <div className='text-sm'>
              Invites to launch party and events
            </div>
          </div>
        </div>

        {/*
        <div className='my-3 text-sm'>
          Not a content creator?
          {' '}
          <Link to='/private-beta/' className='underline'>
            Click here
          </Link>
        </div>
        */}
      </>
    ) : (
      <>
        <h2 className='mt-8'>Sign Up</h2>
        <div className='my-3 text-sm'>
          Are you a content creator?
          {' '}
          <Link to='/private-beta/?content-creator=1' className='underline'>
            Click here
          </Link>
        </div>
      </>
    );

  const formSection = (
      <>
        { formHeader }

        <Formik
          initialValues={{
            reservedUsername: reservedUsername || '',
            instagramUsername: '',
            firstName: '',
            lastName: '',
            email: '',
          }}
          onSubmit={async values => {
            const url = `${modeApiUrl}/api/graphiql`;
            const userType = contentCreator ? '"creator"' : null;
            const requestBody = JSON.stringify({
              query: `mutation {
                createBetaUser(
                  userType: ${userType},
                  reservedUsername: "${values.reservedUsername}",
                  instagramUsername: "${values.instagramUsername}",
                  firstName: "${values.firstName}",
                  lastName: "${values.lastName}",
                  email: "${values.email}",
                ) {
                  id
                  referCodeCreator
                }
              }`
            });
            const response = await fetch(url, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: requestBody,
            }).catch(() => {
              setSubmissionStatus('failed');
            });

            if (response.ok) {
              const responseData = await response.json();
              if (responseData.errors) {
                setSubmissionStatus('failed');
              } else {
                setReferCodeCreator(responseData.data.createBetaUser.referCodeCreator);
                setSubmissionStatus('submitted');
              }
            } else {
              setSubmissionStatus('failed');
              Promise.reject(response);
            }

          }}
          validationSchema={Yup.object().shape({
            reservedUsername: Yup.string().required('Required'),
            firstName: Yup.string().required('Required'),
            lastName: Yup.string().required('Required'),
            email: Yup.string().email().required('Required'),
          })}
        >
          {props => {
            const {
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;

            const fieldClassName = `w-full px-3 py-2 mb-3 rounded-md ${styles.field}`;

            return (
              <form className={`mx-auto ${styles.form}`} onSubmit={handleSubmit}>
                <Field
                  className={
                    errors.reservedUsername && touched.reservedUsername
                      ? `border border-red-500 ${fieldClassName}`
                      : fieldClassName
                  }
                  type='text'
                  name='reservedUsername'
                  placeholder='Reserved username'
                />
                {errors.reservedUsername && touched.reservedUsername && (
                  <div className='text-xs text-red-600 mb-3'>{errors.reservedUsername}</div>
                )}

                {
                  contentCreator && (
                    <Field
                      className={fieldClassName}
                      type='text'
                      name='instagramUsername'
                      placeholder='Instagram username'
                    />
                  )
                }

                <div className='flex justify-between'>
                  <div className='flex-grow mr-2'>
                    <Field
                      className={
                        errors.firstName && touched.firstName
                          ? `border border-red-500 ${fieldClassName}`
                          : fieldClassName
                      }
                      type='text'
                      name='firstName'
                      placeholder='First name'
                    />
                    {errors.firstName && touched.firstName && (
                      <div className='text-xs text-red-600 mb-3'>{errors.firstName}</div>
                    )}
                  </div>
                  <div className='flex-grow ml-2'>
                    <Field
                      className={
                        errors.lastName && touched.lastName
                          ? `border border-red-500 ${fieldClassName}`
                          : fieldClassName
                      }
                      type='text'
                      name='lastName'
                      placeholder='Last name'
                    />
                    {errors.lastName && touched.lastName && (
                      <div className='text-xs text-red-600 mb-3'>{errors.lastName}</div>
                    )}
                  </div>
                </div>

                <Field
                  className={
                    errors.email && touched.email
                      ? `border border-red-500 ${fieldClassName}`
                      : fieldClassName
                  }
                  type='email'
                  name='email'
                  placeholder='Email'
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.email && touched.email && (
                  <div className='text-xs text-red-600 mb-3'>{errors.email}</div>
                )}

                <button type='submit' disabled={isSubmitting} className='bg-black hover:bg-gray-700 text-white font-bold text-sm uppercase py-2 px-4 rounded-sm my-3'>
                  Reserve your username
                </button>

                {
                  submissionStatus === 'failed' && (
                    <div className='text-xs text-red-600 mb-3'>Something went wrong!</div>
                  )
                }
              </form>
            );
          }}
        </Formik>
      </>
  );

  const thankYou = (
    <div className='my-10'>
      <h2 className='md:text-3xl mt-8 mb-8'>Thanks for signing up!</h2>

      <div>
        Send this special link to up to 5 other content creators to invite them to private beta and earn up to $20 for each.
      </div>
      <div>
        Don’t worry, we’ll send you a special link for your followers when we are live!
      </div>

      <div className='my-8'>
        <InviteLink referCodeCreator={referCodeCreator} />
      </div>

      <div>
        This link has also been sent to your e-mail
      </div>

      <Link to='/'>
        <button className='border-solid border border-black hover:bg-gray-400 px-12 py-1 mt-8'>
          Home
        </button>
      </Link>
    </div>
  );

  return (
    <Layout>
      <SEO title='Private Beta - Sign Up' />

      <div className={styles.hero}>
        <div className='container mx-auto text-center pt-8 pb-12 md:pt-64 md:pb-40 px-8'>
          <h1 className={`text-white ${styles.title}`}>Apply For Private Beta Access</h1>
          <p>Sign up for exclusive early access to our platform and be among the first to start earning money from your outfits</p>
        </div>
      </div>

      <div className='container mx-auto px-4 text-center'>
        { submissionStatus === 'submitted' ? thankYou : formSection }
      </div>
    </Layout>
  );
}

export default PrivateBetaPage
